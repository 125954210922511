// src/components/TextComponents.js
import styled from "@emotion/styled";

const Body = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  font-family: "Inter", BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen";
  color: ${(props) => props.theme.colors.black};
`;

const SmallBody = styled.p`
  font-size: 11px;
  font-weight: 400;
  line-height: 28px;
  font-family: "Inter", BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen";
  color: ${(props) => props.theme.colors.darkGrey};
`;

const SubTitle = styled.h2`
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  font-family: "Inter", BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen";
  color: ${(props) => props.theme.colors.blue};
`;

const Headline = styled.h1`
  font-size: 15px;
  font-weight: 700;
  line-height: 20px;
  font-family: "Inter", BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen";
  color: ${(props) => props.theme.colors.darkGrey};
  text-transform: uppercase;
  margin: 0;
`;

export { Body, SmallBody, SubTitle, Headline };
