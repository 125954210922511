import styled from "@emotion/styled";
import React from "react";

const ChatButton = ({ text, onClick }) => {
  return (
    <StyledChatButton onClick={onClick}>
      <StyledText>{text}</StyledText>
    </StyledChatButton>
  );
};
const StyledChatButton = styled.button`
  padding: 10px 12px;
  background: white;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  &:hover {
    p {
      color: ${(props) => props.theme.colors.blue};
    }
  }
`;

const StyledText = styled.p`
  font-family: "Inter", sans-serif;
  font-size: 11px;
  font-weight: 700;
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.darkGrey};
  transition: color 0.25s ease-in-out;
`;
export default ChatButton;
