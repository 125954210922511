import styled from "@emotion/styled";
import React from "react";
import Lottie from "lottie-react";
import lottieAnim from "../json/typing.json";
import { useAppStore } from "../helpers/useAppStore";
import { animated, useSpring } from "@react-spring/web";
const ThinkingIndicator = () => {
  const isThinking = useAppStore((state) => state.isThinking);
  const props = useSpring({
    opacity: isThinking ? 1 : 0,
    display: isThinking ? "flex" : "none",
  });
  return (
    <StyledThinkingIndicator style={props}>
      <UserIcon />
      <LottieWrapper>
        <Lottie animationData={lottieAnim} />
      </LottieWrapper>
    </StyledThinkingIndicator>
  );
};

const UserIcon = () => {
  return (
    <StyledSystemIcon
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="16" cy="16" r="16" fill="white" />
      <path
        d="M14.8426 8.48926L6.4082 20.9509H26.2404L19.7057 11.3767L18.3379 13.4283L14.8426 8.48926Z"
        fill="#0071CE"
      />
    </StyledSystemIcon>
  );
};

const StyledThinkingIndicator = styled(animated.div)`
  display: flex;
  align-self: flex-start;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
`;

const LottieWrapper = styled.div`
  /* z-index: 10; */
  width: 50px;
  transform: translate(-20%, -20%);
`;

const StyledSystemIcon = styled.svg`
  width: 32px;
  height: 32px;
  position: absolute;
  min-height: 32px;
  top: 0;
  min-width: 32px;
  left: 0;
  transform: translateY(-3px) translateX(calc(-100% - 10px));
`;

export default ThinkingIndicator;
