import React from "react";
import styled from "@emotion/styled";
import { Body, Headline } from "./Typography";
import CarouselComponent from "./CarouselComponent";
import { animated, useSpring } from "@react-spring/web";
import { useAppStore } from "../helpers/useAppStore";

const TestPrompts = ({ onSubmit }) => {
  const introComplete = useAppStore((state) => state.introComplete);
  const animationProps = useSpring({
    opacity: introComplete ? 1 : 0,
    y: introComplete ? 0 : 10,
    display: introComplete ? "flex" : "none",
    delay: 1000,
  });
  return (
    <StyledTestPrompts style={animationProps}>
      <TextContainer>
        <StyledBody>Not sure where to start?</StyledBody>
        <Headline>Try one of these Chill prompts</Headline>
      </TextContainer>
      <CarouselComponent onSubmit={onSubmit} />
    </StyledTestPrompts>
  );
};

const StyledTestPrompts = styled(animated.div)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 10px;
  z-index: 100;
  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    padding: 20px 0 25px 0px;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0px;
`;

const StyledBody = styled(Body)`
  color: ${(props) => props.theme.colors.darkGrey};
  margin: 0;
`;

export default TestPrompts;
