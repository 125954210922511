import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import GlobalStyles from "./GlobalStyles";
import { ThemeProvider } from "@emotion/react";
import theme from "./theme";
import { v4 as uuidv4 } from "uuid";
import Header from "./components/Header";
import SidePanel from "./components/SidePanel";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import { useAppStore } from "./helpers/useAppStore";
import { animated, useSpring } from "@react-spring/web";
import styled from "@emotion/styled";
import ShareModal from "./components/ShareModal";

// const API_ENDPOINT_URL = "https://us-central1-chill-gpt.cloudfunctions.net/chatcompsafe";
// const API_ENDPOINT_URL = 'https://us-central1-chill-gpt.cloudfunctions.net/chatcomp';

// // prod
const API_ENDPOINT_URL = "https://chillgptnewbackend-prod-ruhewamrzq-uc.a.run.app/get_chatgpt_response"
const ENVIRONMENT = "Production"
// // prod

// // // staging
// const API_ENDPOINT_URL = "https://chillgptnewbackend-ruhewamrzq-uc.a.run.app/get_chatgpt_response"
// const ENVIRONMENT = "Staging"
// // // staging 

// dev
// const API_ENDPOINT_URL = "https://chillgptnewbackend-dev-ruhewamrzq-uc.a.run.app/get_chatgpt_response"
// const ENVIRONMENT = "Development"
// dev

// local
// const API_ENDPOINT_URL = "http://localhost:8000/get_chatgpt_response"
// const ENVIRONMENT = "Local"

const App = () => {
  const config = require("./config");
  const apiKey = config.INTERNAL_API_KEY;
  const [sessionId, setSessionId] = useState(uuidv4());
  const [hide, setHide] = useState(true);
  const [counter, setCounter] = useState(0);
  const [messages, setMessages] = useState([]);
  const [val, setVal] = useState("");
  const sideBarOpen = useAppStore((state) => state.sideBarOpen);
  const shareOpen = useAppStore((state) => state.shareOpen);
  const setIsTyping = useAppStore((state) => state.setIsTyping);
  const setIsThinking = useAppStore((state) => state.setIsThinking);
  const loopActive = useRef(true); // Add a ref to control loop
  const [loopStopped, setLoopStopped] = useState(false);

  useEffect(() => {
    setSessionId(uuidv4());
    setMessages([]);
  }, [counter]);

  // const handleUserMessage = async (text) => {
  //   const newUserMessage = { text, isUser: true };
  //   resumeGenerating();
  //   setMessages([...messages, newUserMessage]);

  //   const conversation = messages.map((message) => {
  //     return {
  //       role: message.isUser ? "user" : "system",
  //       content: message.text,
  //       sessionId: sessionId,
  //     };
  //   });

  //   conversation.push({ role: "user", content: text });
  //   setIsThinking(true);
  //   try {
  //     console.log("CONVERSATION: ", conversation)
  //     const response = await axios.post(
  //       API_ENDPOINT_URL,
  //       {
  //         conversation: conversation,
  //         sessionId: sessionId,
  //       },
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           'Authorization': `Bearer ${apiKey}`
  //         },
  //       }
  //     );

  //     setIsThinking(false);
  //     const words = response.data.conversation.slice(-1)[0].content.split(" ");
  //     let currentMessage = "";
  //     setMessages((prevMessages) => [...prevMessages, currentMessage]);
  //     generateBotMessage(0, words, currentMessage);
  //   } catch (error) {
  //     setIsThinking(false);
  //     alert("Error fetching response from API:", error);
  //     alert(error);
  //     console.error("Error fetching response from API:", error);
  //   }
  // };

  // const handleUserMessage = async (text, includeInHistory = true) => {
  //   const newUserMessage = { text, isUser: true, includeInHistory };
  //   resumeGenerating();
  //   setMessages([...messages, newUserMessage]);

  //   const conversation = messages
  //     .filter((message) => message.includeInHistory)
  //     .map((message) => {
  //       return {
  //         role: message.isUser ? "user" : "system",
  //         content: message.text,
  //         sessionId: sessionId,
  //       };
  //     });

  //   if (includeInHistory) {
  //     conversation.push({ role: "user", content: text });
  //   }

  //   setIsThinking(true);
  //   try {
  //     console.log("CONVERSATION: ", conversation)
  //     const response = await axios.post(
  //       API_ENDPOINT_URL,
  //       {
  //         conversation: conversation,
  //         sessionId: sessionId,
  //       },
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           'Authorization': `Bearer ${apiKey}`
  //         },
  //       }
  //     );

  //     setIsThinking(false);
  //     const words = response.data.conversation.slice(-1)[0].content.split(" ");
  //     let currentMessage = "";
  //     setMessages((prevMessages) => [...prevMessages, currentMessage]);
  //     generateBotMessage(0, words, currentMessage);
  //   } catch (error) {
  //     setIsThinking(false);
  //     alert("Error fetching response from API:", error);
  //     alert(error);
  //     console.error("Error fetching response from API:", error);
  //   }
  // };

  // // const generateBotMessage = async (index, words, currentMessage) => {
  // //   if (index < words.length && loopActive.current) {
  // //     const nextMessage = currentMessage + " " + words[index];
  // //     // Set isTyping to true to show typing indicator
  // //     setIsTyping(true);
  // //     setTimeout(async () => {
  // //       if (loopActive.current) {
  // //         const botMessage = { text: nextMessage.trim(), isUser: false };
  // //         setMessages((prevMessages) => [
  // //           ...prevMessages.slice(0, -1),
  // //           botMessage,
  // //         ]);
  // //         await generateBotMessage(index + 1, words, nextMessage);
  // //       } else {
  // //         setIsTyping(false);
  // //       }
  // //       // Check if the generateBotMessage function has finished processing words
  // //       if (index === words.length - 1) {
  // //         // Set isTyping to false when generateBotMessage finishes processing words
  // //         setIsTyping(false);
  // //       } else if (!loopActive.current) {
  // //         // Set isTyping to false when generateBotMessage stops
  // //         setIsTyping(false);
  // //       }
  // //     }, 10); // Change delay as needed
  // //   }
  // // };

  // const generateBotMessage = async (index, words, currentMessage, includeInHistory = true) => {
  //   if (index < words.length && loopActive.current) {
  //     const nextMessage = currentMessage + " " + words[index];
  //     // Set isTyping to true to show typing indicator
  //     setIsTyping(true);
  //     setTimeout(async () => {
  //       if (loopActive.current) {
  //         const botMessage = { text: nextMessage.trim(), isUser: false, includeInHistory };
  //         setMessages((prevMessages) => [
  //           ...prevMessages.slice(0, -1),
  //           botMessage,
  //         ]);
  //         await generateBotMessage(index + 1, words, nextMessage, includeInHistory);
  //       } else {
  //         setIsTyping(false);
  //       }
  //       // Check if the generateBotMessage function has finished processing words
  //       if (index === words.length - 1) {
  //         // Set isTyping to false when generateBotMessage finishes processing words
  //         setIsTyping(false);
  //       } else if (!loopActive.current) {
  //         // Set isTyping to false when generateBotMessage stops
  //         setIsTyping(false);
  //       }
  //     }, 40); // Change delay as needed
  //   }
  // };

  const handleUserMessage = async (text, includeInHistory = true) => {
    const newUserMessage = { text, isUser: true, includeInHistory };
    resumeGenerating();
    setMessages([...messages, newUserMessage]);

    const conversation = messages
      .filter((message) => message.includeInHistory)
      .map((message) => {
        return {
          role: message.isUser ? "user" : "system",
          content: message.text,
          sessionId: sessionId,
        };
      });

    if (includeInHistory) {
      conversation.push({ role: "user", content: text });
    }

    setIsThinking(true);
    try {
      const response = await axios.post(
        API_ENDPOINT_URL,
        {
          conversation: conversation,
          sessionId: sessionId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiKey}`,
          },
        }
      );
      console.log("response: ", response);
      setIsThinking(false);
      const words = response.data.conversation.slice(-1)[0].content.split(" ");
      let currentMessage = "";
      setMessages((prevMessages) => [...prevMessages, currentMessage]);

      // If response is flagged, do not include it in history.
      const includeInHistory = !response.data.flagged;
      if (!includeInHistory) {
        newUserMessage.includeInHistory = false;
      }
      generateBotMessage(0, words, currentMessage, includeInHistory);
    } catch (error) {
      setIsThinking(false);
      alert("Error fetching response from API:", error);
      alert(error);
      console.error("Error fetching response from API:", error);
    }
  };

  const generateBotMessage = async (
    index,
    words,
    currentMessage,
    includeInHistory = true
  ) => {
    if (index < words.length && loopActive.current) {
      const nextMessage = currentMessage + " " + words[index];
      setIsTyping(true);
      setTimeout(async () => {
        if (loopActive.current) {
          const botMessage = {
            text: nextMessage.trim(),
            isUser: false,
            includeInHistory,
          };
          setMessages((prevMessages) => [
            ...prevMessages.slice(0, -1),
            botMessage,
          ]);
          await generateBotMessage(
            index + 1,
            words,
            nextMessage,
            includeInHistory
          );
        } else {
          setIsTyping(false);
          // console.log(messages)
        }
        if (index === words.length - 1) {
          setIsTyping(false);
        } else if (!loopActive.current) {
          setIsTyping(false);
        }
      }, 120);
    }
  };

  const stopGenerating = () => {
    loopActive.current = false;
    setLoopStopped(true);
  };

  const resumeGenerating = () => {
    if (loopStopped) {
      loopActive.current = true;
      setLoopStopped(false);
    }
  };

  const animProps = useSpring({
    opacity: sideBarOpen || shareOpen ? 1 : 0,
    pointerEvents: sideBarOpen || shareOpen ? "auto" : "none",
  });
  if (hide) {
    return (
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <StyledLogin>
          <FormWrapper>
            <input
              placeholder="password.."
              onChange={(e) => setVal(e.target.value)}
            />
            <button
              onClick={() => {
                if (val.toLowerCase() === "st@ychill") setHide(false);
              }}
            >
              Log in
            </button>
            <br />
            {/* <p>Environment: {process.env.REACT_APP_NODE_ENV}</p> */}
            {/* <p>Environment: {"Staging"}</p> */}
            <p>Environment: {ENVIRONMENT}</p>

          </FormWrapper>
        </StyledLogin>
      </ThemeProvider>
    );
  } else {
    return (
      <ThemeProvider theme={theme}>
        <div style={{ position: "relative" }}>
          <Header setCounter={setCounter} />
          <SidePanel />
          <SidePanelOverlay style={animProps} />
          {shareOpen && <ShareModal />}
          <GlobalStyles />
          <BrowserRouter>
            <Routes>
              <Route
                path="/"
                element={
                  <HomeMemoized
                    stopGenerating={stopGenerating}
                    messages={messages}
                    handleUserMessage={handleUserMessage}
                  />
                }
              />
            </Routes>
          </BrowserRouter>
        </div>
      </ThemeProvider>
    );
  }
};

const HomeMemoized = React.memo(Home);

const SidePanelOverlay = styled(animated.div)`
  position: absolute;
  left: 0;
  top: 0;
  z-index: 9988;
  width: 100%;
  height: 100%;
  background: rgba(9, 32, 69, 0.7);
  pointer-events: none;
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  background: ${(props) => props.theme.colors.mediumBlue};
  padding: 50px;
  box-shadow: ${(props) => props.theme.shadows.hovered};
  border-radius: 10px;
`;

const StyledLogin = styled.div`
  font-family: "Inter", sans-serif;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0;
  background: ${(props) => props.theme.colors.lightGray};
  gap: 30px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  input {
    border: none;
    font-size: 18px;
    background: ${(props) => props.theme.colors.lightGrey};
    color: ${(props) => props.theme.colors.darkBlue};
    border-radius: 5px;
    box-shadow: ${(props) => props.theme.shadows.hovered};

    &:focus,
    &:active {
      outline: none;
    }
    &::placeholder {
      color: ${(props) => props.theme.colors.lightGray};
    }
  }
  label {
    text-align: left;
  }
  button {
    box-shadow: ${(props) => props.theme.shadows.hovered};

    border: none;
    background: ${(props) => props.theme.colors.darkBlue};
    cursor: pointer;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 1.2rem;
  }
`;

export default App;
