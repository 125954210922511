import React, { useState } from "react";
import styled from "@emotion/styled";
import { Body } from "./Typography";
import { IconLeft, IconRight } from "./SvgIcons";
import { useAppStore } from "../helpers/useAppStore";
import useScreenWidth from "../helpers/useScreenWidth";
import { useSwipeable } from "react-swipeable";

const CarouselComponent = ({ onSubmit }) => {
  const setChatOpen = useAppStore((state) => state.setChatOpen);
  const { isTablet, isMobile } = useScreenWidth();
  const numSlidesToShow = isMobile ? 1 : isTablet ? 2 : 3;

  const slides = [
    "Compose a beautiful poem for my roommate",
    "Write a recipe for chicken and bell peppers",
    // "What's a fun fact about the universe?",
    "Plan the perfect proposal for my girlfriend.",
    "Write a cover letter for a job in IT.",
    // "Please plan itinerary for an exciting vacation.",
    // "Explain string theory to me like I'm 5.",
    "Write a '90s alt-rock song for a friend.",
  ];
  const [currentSlide, setCurrentSlide] = useState(0);
  const totalSlides = slides.length;

  const handlePrevClick = () => {
    setCurrentSlide((prevState) => prevState - 1);
  };

  const handleNextClick = () => {
    setCurrentSlide((prevState) => prevState + 1);
  };

  const handleButtonClick = (text) => {
    console.log(text);
    onSubmit(text);
    setChatOpen(true);
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      if (currentSlide < totalSlides - numSlidesToShow) {
        handleNextClick();
      }
    },
    onSwipedRight: () => {
      if (currentSlide > 0) {
        handlePrevClick();
      }
    },
    preventDefaultTouchmoveEvent: true,
    trackMouse: false,
  });

  return (
    <SliderContainer {...handlers}>
      <SliderContent>
        <SlideWrapper
          style={{
            transform: `translateX(-${(100 / totalSlides) * currentSlide}%)`,
          }}
        >
          {slides.map((slide, index) => (
            <Slide onClick={() => handleButtonClick(slide)} key={index}>
              <Body>"{slide}"</Body>
            </Slide>
          ))}
        </SlideWrapper>
      </SliderContent>
      {!isTablet && !isMobile && (
        <>
          <ArrowButtonPrev
            disabled={currentSlide === 0}
            onClick={handlePrevClick}
          >
            <IconLeft />
          </ArrowButtonPrev>

          <ArrowButtonNext
            disabled={currentSlide === totalSlides - 3}
            onClick={handleNextClick}
          >
            <IconRight />
          </ArrowButtonNext>
        </>
      )}
    </SliderContainer>
  );
};

const SliderContainer = styled.div`
  position: relative;
  margin: 0 auto;
  display: flex;
  gap: 20px;
`;

const SliderContent = styled.div`
  overflow-x: hidden;
  overflow-y: visible;
  display: flex;
  gap: 20px;
  padding: 10px;
  width: calc(206px * 3 + 20px * 2);
  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    padding: 0px;
  }
  @media (max-width: 768px) {
    width: 90%;
  }

  @media (max-width: 480px) {
    width: 90%;
  }
`;

const SlideWrapper = styled.div`
  display: flex;
  align-items: center;
  transition: transform 0.3s ease-in-out;
  gap: 20px;
  width: 300%; // This should be adjusted depending on the number of cards you want to show at once
`;

const Slide = styled.button`
  flex: 1;
  width: 206px;
  height: 90px;
  background-color: #fff;
  text-align: center;
  border: 1px solid #f2f7fc;
  border-radius: 5px;
  box-shadow: 0px 4px 10px rgba(136, 121, 121, 0.1);
  transition: box-shadow 0.2s ease-in-out;
  &:hover {
    box-shadow: 0px 4px 12px rgba(136, 121, 121, 0.2);
    border: 1px solid ${(props) => props.theme.colors.blue};
    p {
      color: ${(props) => props.theme.colors.blue};
    }
  }
  cursor: pointer;
`;

const ArrowButton = styled.button`
  // Add custom styles for the arrow buttons
  background-color: transparent;
  outline: none;
  cursor: pointer;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  height: 54px;
  position: absolute;
  width: 54px;
  border-radius: 50%;
  border: 1px solid ${(props) => props.theme.colors.blue};
  transition: opacity 0.2s ease-in-out, background-color 0.2s ease-in-out;
  &:disabled {
    opacity: 0.5;
  }
  &:active:enabled {
    background: ${(props) => props.theme.colors.blue};
  }
  &:hover:enabled {
    background: ${(props) => props.theme.colors.blue};
    path {
      fill: #fff;
    }
  }
  path {
    transition: fill 0.2s ease-in-out;
  }
  img {
    width: 20px;
    height: 20px;
    transition: scale 0.2s ease-in-out;
  }
`;

const ArrowButtonPrev = styled(ArrowButton)`
  left: -70px;
`;

const ArrowButtonNext = styled(ArrowButton)`
  right: -70px;
`;

export default CarouselComponent;
