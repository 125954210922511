// src/GlobalStyles.js
import { css, Global } from "@emotion/react";

const globalStyles = css`
  @font-face {
    font-family: "Inter";
    src: url("./fonts/Inter-Regular.ttf") format("ttf"),
      url("./fonts/Inter-Regular.woff") format("woff"),
      url("./fonts/Inter-Regular.woff2") format("woff2");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: "Inter";
    src: url("./fonts/Inter-Bold.ttf") format("ttf"),
      url("./fonts/Inter-Bold.woff") format("woff"),
      url("./fonts/Inter-Bold.woff2") format("woff2");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }
  html {
    margin: 0;
    body {
      margin: 0 !important;
    }
  }
  body {
    font-family: "Inter", BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
      "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  @media screen and (-webkit-min-device-pixel-ratio: 0) {
    select,
    textarea,
    input {
      font-size: 16px !important;
    }
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin: 0;
  }
  #teconsent {
    text-align: right;
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 900;
  }
  #consent_blackbar {
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 1000;
  }
`;

const GlobalStyles = () => <Global styles={globalStyles} />;

export default GlobalStyles;
