import React from "react";
import styled from "@emotion/styled";

// SendButton component to display a send button with SVG icon
const SendButton = ({ handleClick, isDisabled }) => {
  return (
    <StyledSendButton
      isDisabled={isDisabled}
      disabled={isDisabled}
      onClick={handleClick}
    >
      <SendIcon />
    </StyledSendButton>
  );
};

// SVG icon for the send button
const SendIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.9769 7.56576C14.1228 7.37733 14.1046 7.10731 13.9337 6.94128C13.7628 6.77525 13.496 6.7672 13.3154 6.92232L5.04836 14.0387L0.682608 12.3307C0.428237 12.2319 0.220425 12.0383 0.10346 11.7889C-0.0140724 11.5402 -0.0322417 11.2546 0.052927 10.9927C0.137528 10.7307 0.318653 10.5112 0.558829 10.3808L18.6827 0.108143C18.8735 0.0041584 19.0955 -0.0262902 19.3062 0.023117C19.5174 0.0719497 19.7036 0.197191 19.8308 0.374713C19.9574 0.552809 20.0165 0.771119 19.996 0.98943L18.4062 17.7574C18.3739 18.1062 18.1808 18.4193 17.8856 18.6014C17.5897 18.7841 17.2269 18.8145 16.9055 18.6841L11.5927 16.6027L8.25694 19.7653C8.09172 19.921 7.87312 20.0055 7.64714 19.9997C7.42115 19.994 7.20653 19.8986 7.04982 19.7349C6.89254 19.5706 6.80567 19.3505 6.80737 19.1219V16.6383L13.9774 7.56576H13.9769Z"
      fill="#94B4E0"
    />
  </svg>
);

const StyledSendButton = styled.button`
  position: absolute;
  right: 20px;
  bottom: 15px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  cursor: ${(props) => (props.isDisabled ? "not-allowed" : "pointer")};
  transition: scale 0.25s ease-in-out;
  z-index: 3;
  &:hover:enabled {
    scale: 1.2;
  }
  &:active:enabled {
    scale: 0.9;
  }
  svg {
    height: 20px;
    width: 20px;
  }
  path {
    transition: fill 0.25s ease-in-out;
    fill: ${(props) =>
      props.isDisabled ? "#94B4E0" : props.theme.colors.blue};
  }
`;
export default SendButton;
