import React from "react";
import styled from "@emotion/styled";
import IconButton from "./IconButton";
import { CloseIcon, InfoIcon } from "./SvgIcons";
import { useAppStore } from "../helpers/useAppStore";
import FadingImage from "./FadingImage";
import smallLogo from "../images/logo-small.png";
import { useSpring, animated } from "@react-spring/web";
import HeaderRight from "./HeaderRight";
const Header = ({ setCounter }) => {
  const setSideBarOpen = useAppStore((state) => state.setSideBarOpen);
  const sideBarOpen = useAppStore((state) => state.sideBarOpen);
  const chatOpen = useAppStore((state) => state.chatOpen);
  const setChatOpen = useAppStore((state) => state.setChatOpen);
  const introComplete = useAppStore((state) => state.introComplete);
  const props = useSpring({
    opacity: introComplete ? 1 : 0,
    config: { duration: 500 },
    delay: 1000,
  });

  const iconButtonAnimProps = useSpring({
    left: introComplete ? "10px" : "-100px",
    delay: 1000,
  });
  return (
    <StyledHeader style={props}>
      <IconWrapper style={iconButtonAnimProps}>
        <IconButton
          onClick={() => setSideBarOpen(!sideBarOpen)}
          Icon={sideBarOpen ? CloseIcon : InfoIcon}
        />
        <ImageWrapper
          role="button"
          onClick={() => {
            setChatOpen(false);
            setCounter((prev) => prev + 1);
          }}
          style={{ opacity: chatOpen ? 1 : 0 }}
        >
          <img src={smallLogo} alt="logo small" />
        </ImageWrapper>
      </IconWrapper>
      <HeaderRight />
    </StyledHeader>
  );
};

const StyledHeader = styled(animated.div)`
  display: flex;
  position: absolute;
  z-index: 1000;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  top: 10px;
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    position: fixed;
  }
`;

const IconWrapper = styled(animated.div)`
  display: flex;
  gap: 20px;
  z-index: 10;
  margin-left: 10px;
  align-items: center;
`;

const ImageWrapper = styled.div`
  transition: opacity 0.3s ease-in-out;
  cursor: pointer;
  width: 143px;
  height: 23px;
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
`;
const HeaderMemoized = React.memo(Header);

export default HeaderMemoized;
