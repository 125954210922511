import React from "react";
import styled from "@emotion/styled";
import Lottie from "lottie-react";
import logoAnim from "../json/logo2.json";
import ChatInputHome from "./ChatInputHome";
import { Body } from "./Typography";
import { useAppStore } from "../helpers/useAppStore";
import { animated, config, easings, useSpring } from "@react-spring/web";
import homevid from "../videos/mountains.mp4";
import introAnim from "../json/intro.json";
import useScreenWidth from "../helpers/useScreenWidth";

const TopHomeContent = ({ handleUserMessage }) => {
  const introComplete = useAppStore((state) => state.introComplete);
  const setIntroComplete = useAppStore((state) => state.setIntroComplete);
  const { isMobile } = useScreenWidth();
  const heightVal = isMobile ? "70vh" : "75vh";
  const wrapperAnimProps = useSpring({
    height: introComplete ? `${heightVal}` : "100vh",
    delay: 500,
  });
  const logoAnimProps = useSpring({
    config: { duration: 350, easing: easings.easeInCubic },
    delay: 150,
    transform: introComplete ? "translate(0%, 0%)" : "translate(0%, 20%)",
  });

  const inputAnimProps = useSpring({
    y: introComplete ? 0 : 100,
    opacity: introComplete ? 1 : 0,
    delay: 550,
    config: config.slow,
  });

  const bodyAnimProps = useSpring({
    y: introComplete ? 0 : -20,
    opacity: introComplete ? 1 : 0,
    delay: 650,
    config: config.slow,
  });

  return (
    <TopContentWrapper style={wrapperAnimProps}>
      <StyledAnimatedVideo autoPlay muted playsInline>
        <source src={homevid} type={"video/mp4"} />
        Your browser does not support the video tag.
      </StyledAnimatedVideo>
      <LogoAnimWrapper style={logoAnimProps}>
        {!introComplete && (
          <StyledIntroLottie
            onComplete={() => setIntroComplete(true)}
            loop={false}
            animationData={introAnim}
          />
        )}
        {introComplete && <StyledLogoLottie animationData={logoAnim} />}
      </LogoAnimWrapper>
      <ContentWrapper>
        <animated.div style={inputAnimProps}>
          <ChatInputHome onSubmit={handleUserMessage} />
        </animated.div>
        <animated.div style={bodyAnimProps}>
          <StyledBody>
            Coors Light is proud to introduce the newest innovation in
            artificial intelligence: ChillGPT. Our AI language model is
            programmed to do two things: (1) help people chill by completing
            tasks for them and (2) turn every response into an ad for Coors
            Light. And by seamlessly turning every interaction into a chance for
            product placement, this ad-based chatbot that will stay free for
            you, the user—No subscriptions. No fees. Just Mountain Cold Coors
            Light.
          </StyledBody>
        </animated.div>
      </ContentWrapper>
    </TopContentWrapper>
  );
};

const TopContentWrapper = styled(animated.div)`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  overflow: hidden;
  justify-content: center;
  overflow: hidden;
`;

const StyledAnimatedVideo = styled(animated.video)`
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 100;
  position: absolute;
`;

const StyledIntroLottie = styled(Lottie)`
  position: absolute;
  width: 133%;
  height: 100%;
  z-index: 10000;
  transform: translateX(-66px);
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    transform: translateX(-50px);
  }
`;

const StyledLogoLottie = styled(Lottie)`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 100;
`;
const ContentWrapper = styled.div`
  position: relative;
  z-index: 100;
`;

const LogoAnimWrapper = styled(animated.div)`
  position: relative;
  z-index: 100;
  pointer-events: none;
  width: 400px;
  height: 368px;
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    width: 300px;
    height: 242px;
  }
`;

const StyledBody = styled(Body)`
  position: relative;
  width: 705px;
  text-align: center;
  margin: 20px auto 20px auto;
  color: ${({ theme }) => theme.colors.darkBlue};
  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    width: calc(100% - 85px);
    color: #6e6e6e;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    width: calc(100% - 44px);
    font-size: 11px;
    line-height: 18px;
  }
`;

export default TopHomeContent;
