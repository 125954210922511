import React from "react";
import styled from "@emotion/styled";
import IconButton from "./IconButton";
import { Headline } from "./Typography";
import { CloseIcon, EmailIcon, FacebookIcon, TwitterIcon } from "./SvgIcons";
import { useAppStore } from "../helpers/useAppStore";
import { useSpring, animated, config } from "@react-spring/web";
import {
  FacebookShareButton,
  TwitterShareButton,
  EmailShareButton,
} from "react-share";

const ShareModal = () => {
  const setShareOpen = useAppStore((state) => state.setShareOpen);
  const fadeUpSpring = useSpring({
    from: { opacity: 0, transform: "translate3d(-50%, -40% , 0)" },
    to: { opacity: 1, transform: "translate3d(-50%, -50%, 0)" },
    config: config.stiff,
  });
  const fadeUpChildren = useSpring({
    from: { opacity: 0, transform: "translate3d(0, 40px , 0)" },
    to: { opacity: 1, transform: "translate3d(0%, 0, 0)" },
    config: config.stiff,
  });
  const fadeInText = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
  });
  const staggeredSprings = [
    useSpring({ delay: 100, ...fadeUpChildren }),
    useSpring({ delay: 200, ...fadeUpChildren }),
    useSpring({ delay: 300, ...fadeUpChildren }),
  ];

  const shareUrl = "https://chillgpt.coorslight.com";
  const title =
    "Have a chat with mountain cold Coors Lightʼs ChillGPT, the first fully free, ad-based AI chatbot.";

  return (
    <StyledShareModal style={fadeUpSpring}>
      <ExitButtonWrapper onClick={() => setShareOpen(false)}>
        <CloseIcon />
      </ExitButtonWrapper>
      <animated.div style={fadeInText}>
        <StyledHeadline>SHARE CHILLGPT</StyledHeadline>
      </animated.div>
      <SocialIcons>
        <animated.div style={staggeredSprings[0]}>
          <FacebookShareButton url={shareUrl} quote={title}>
            <IconButton as="div" Icon={FacebookIcon} />
          </FacebookShareButton>
        </animated.div>
        <animated.div style={staggeredSprings[1]}>
          <TwitterShareButton url={shareUrl} title={title}>
            <IconButton as="div" Icon={TwitterIcon} />
          </TwitterShareButton>
        </animated.div>
        <animated.div style={staggeredSprings[2]}>
          <EmailShareButton url={shareUrl} subject={"ChillGPT"} body={title}>
            <IconButton as="div" Icon={EmailIcon} />
          </EmailShareButton>
        </animated.div>
      </SocialIcons>
    </StyledShareModal>
  );
};

const StyledShareModal = styled(animated.div)`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 290px;
  height: 182px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  gap: 20px;
  position: absolute;
  z-index: 10000;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: white;
`;

const ExitButtonWrapper = styled.button`
  position: absolute;
  top: 7px;
  right: 7px;
  border: none;
  border-radius: 50%;
  width: 44px !important;
  height: 44px !important;
  background: ${(props) => props.theme.colors.lightGrey};
`;

const StyledHeadline = styled(Headline)`
  color: ${(props) => props.theme.colors.blue};
`;

const SocialIcons = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
`;

export default ShareModal;
