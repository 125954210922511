import React, { useState, useCallback, useRef, useEffect } from "react"; // Import useCallback for memoization
import styled from "@emotion/styled";
import SendButton from "./SendButton";
import TextareaAutosize from "react-textarea-autosize";
import { useAppStore } from "../helpers/useAppStore";

const ChatInputHome = ({ onSubmit }) => {
  const [input, setInput] = useState("");
  const setChatInputHomeY = useAppStore((state) => state.setChatInputHomeY);
  const chatOpen = useAppStore((state) => state.chatOpen);
  const setChatOpen = useAppStore((state) => state.setChatOpen);
  // Handle input submission and reset the input
  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      if (input.trim()) {
        onSubmit(input);
        setInput("");
      }
      setChatOpen(true);
    },
    [input, onSubmit, setChatOpen]
  );

  // Check for Enter key press and call handleSubmit
  const handleKeyPress = useCallback(
    (e) => {
      if (e.key === "Enter" && !e.shiftKey && input.length > 0) {
        e.preventDefault();
        handleSubmit(e);
      }
    },
    [handleSubmit, input.length]
  );

  const chatInputHomeRef = useRef(null);

  useEffect(() => {
    const updateChatInputHomeY = () => {
      if (chatInputHomeRef.current) {
        const rect = chatInputHomeRef.current.getBoundingClientRect();
        setChatInputHomeY(rect.y);
      }
    };
    updateChatInputHomeY();

    const handleResize = () => {
      updateChatInputHomeY();
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [setChatInputHomeY]);

  // Update input value on change
  const handleInputChange = useCallback((e) => {
    setInput(e.target.value);
  }, []);
  return (
    <StyledForm
      chatOpen={chatOpen}
      ref={chatInputHomeRef}
      onSubmit={handleSubmit}
    >
      <InputWrapper>
        <StyledInput
          type="text"
          value={input}
          onKeyPress={handleKeyPress}
          onChange={handleInputChange}
          placeholder={"How can I help you chill?"}
        />

        <SendButton handleClick={handleSubmit} isDisabled={!input.length} />
      </InputWrapper>
    </StyledForm>
  );
};

// Form styled component
const StyledForm = styled.form`
  position: relative;
  width: 100%;
  opacity: ${(props) => (props.chatOpen ? 0 : 1)};
`;

// Input wrapper styled component
const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: fit-content;
  margin: 0 auto;
  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    width: 80%;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    max-width: calc(100% - 20px);
    width: calc(100% - 20px);
  }
`;

// Input styled component
const StyledInput = styled(TextareaAutosize)`
  font-family: "Inter", sans-serif;
  line-height: 22px;
  max-width: 650px;
  width: 650px;
  padding: 16px;
  padding-right: 60px;
  border-radius: 27px;
  background: #ffffff;
  border: none;
  box-shadow: ${(props) => props.theme.shadows.regular};
  font-size: 14px;
  color: ${(props) => props.theme.colors.black};
  transition: box-shadow 0.2s ease-in-out;
  resize: none;
  position: relative;
  z-index: 2;

  &:focus,
  &:active {
    outline: none;
    box-shadow: ${(props) => props.theme.shadows.hovered};
  }
  &::placeholder {
    font-size: 14px;
    color: ${(props) => props.theme.colors.blue};
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    max-width: 100%;
    width: 100%;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    max-width: calc(100% - 68px);
    width: calc(100% - 68px);
  }
`;

export default ChatInputHome;
