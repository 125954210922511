import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { Body } from "./Typography";
import { animated, useSpring } from "@react-spring/web";
import { useAppStore } from "../helpers/useAppStore";
const UserMessage = ({ text, messages, messageNumber }) => {
  const isTyping = useAppStore((state) => state.isTyping);
  const isLastMessage = messages.length - 1 === messageNumber;

  const animProps = useSpring({
    from: { y: 100, opacity: 0 },
    to: { y: 0, opacity: 1 },
    delay: 350,
  });

  return (
    <StyledUserMessage style={animProps}>
      <StyledMessage lastMessage={isLastMessage} typing={isTyping}>
        {text}
      </StyledMessage>
      <UserIcon />
    </StyledUserMessage>
  );
};

const UserIcon = () => {
  return (
    <StyledUserIcon
      width="32"
      height="37"
      viewBox="0 0 32 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="16" cy="21" r="16" fill="#092045" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.77957 28.7475C8.92984 26.9792 9.70563 25.3659 10.9927 24.136C12.4327 22.76 14.3527 22.056 16.3367 22.152C19.9604 22.3363 22.9063 25.2024 23.1946 28.7706C21.2122 30.3163 18.7194 31.2378 16.0114 31.24H15.9923C13.2708 31.2378 10.7667 30.3071 8.77957 28.7475ZM15.9847 12.04C13.5527 12.04 11.5687 14.024 11.5687 16.456C11.5687 18.856 13.5527 20.84 15.9847 20.84C18.4167 20.84 20.4007 18.888 20.4007 16.456C20.4007 14.024 18.4167 12.04 15.9847 12.04Z"
        fill="#F2F7FC"
      />
    </StyledUserIcon>
  );
};

const StyledUserMessage = styled(animated.div)`
  align-self: flex-end;
  display: flex;
  justify-content: center;
  gap: 10px;
  position: relative;
  z-index: 0;
`;

const StyledMessage = styled(Body)`
  color: ${(props) => props.theme.colors.white};
  padding: 12px 20px;
  border-radius: 20px 0px 20px 20px;
  text-align: right;
  /* background: #76777a; */
  background: #0071ce;
  overflow: hidden;
  position: relative;
  /* z-index: 1; */
  /* &::before {
    transition: transform 0.5s 1s ease-in-out;
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #0071ce;
    z-index: -1;
    transform: ${(props) =>
    props.typing ? "translateY(0%)" : "translateY(100%)"};
  } */
`;

const StyledUserIcon = styled.svg`
  width: 32px;
  height: 37px;
  min-height: 37px;
  min-width: 32px;
  position: absolute;
  right: 0;
  top: -5px;
  z-index: -1;
  transform: translateX(calc(100% + 10px));
`;

export default UserMessage;
