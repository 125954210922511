import React, { useEffect, useState } from "react";
import tooltip from "../images/tooltip.gif";
import styled from "@emotion/styled";
import { Body, Headline } from "./Typography";
import { useSpring, animated } from "@react-spring/web";
import { useAppStore } from "../helpers/useAppStore";

const KeepChattingModal = ({ messages }) => {
  const [closeModal, setCloseModal] = useState(false);
  const isTyping = useAppStore((state) => state.isTyping);
  const isThinking = useAppStore((state) => state.isThinking);
  useEffect(() => {}, [messages]);
  const keepChattingOpen = messages.length === 2 && !isTyping && !isThinking;
  const modalAnimation = useSpring({
    transform:
      keepChattingOpen && !closeModal
        ? "translate(-50%, -5%)"
        : "translate(-50%, 150%)",
    // config: { duration: 500 },
    delay: closeModal ? 0 : 500,
    reverse: closeModal || !keepChattingOpen,
  });

  return (
    <StyledKeepChattingModal style={modalAnimation}>
      <MountainGif src={tooltip} alt="mountains" />
      <ContentWrapper>
        <StyledHeadline>
          Need anything else, or are you chilling?
        </StyledHeadline>
        <Body>
          Ask me anything. I can remember our conversations up to 4,000 words.
        </Body>
        <LetsGoButton
          onClick={() => {
            setCloseModal(true);
          }}
        >
          <StyledHeadline>Let's go</StyledHeadline>
        </LetsGoButton>
      </ContentWrapper>
      <UpsideDownTriangle />
    </StyledKeepChattingModal>
  );
};

const StyledKeepChattingModal = styled(animated.div)`
  height: 273px;
  width: 280px;
  position: fixed;
  bottom: 95px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 15;
  /* overflow: hidden; */
  background: white;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const MountainGif = styled.img`
  top: 0;
  left: 0;
  width: 100%;
  height: 100px;
  z-index: 16;
  border-radius: 10px 10px 0 0;
`;

const StyledHeadline = styled(Headline)`
  color: ${({ theme }) => theme.colors.blue};
  max-width: 200px;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: center;
  text-align: center;
  p {
    max-width: 252px;
  }
`;
const LetsGoButton = styled.button`
  background: #f2f7fc;
  border-radius: 100px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 129px;
  cursor: pointer;
  height: 44px;
`;

const UpsideDownTriangle = styled.div`
  position: relative;
  width: 0px;
  height: 0px;
  bottom: -5px;
  z-index: 10;
  background: red;
  left: 50%;
  &::before {
    content: "";
    position: absolute;
    left: 50%;
    top: 100%;
    transform: translateX(-50%) rotate(180deg);
    width: 0px;
    height: 0px;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-bottom: 15px solid white;
  }
`;
export default KeepChattingModal;
