import React from "react";
import styled from "@emotion/styled";
import TestPrompts from "../components/TestPrompts";
import { animated, useSpring } from "@react-spring/web";
import ChatInput from "../components/ChatInput";
import { useAppStore } from "../helpers/useAppStore";
import ChatHistory from "../components/ChatHistory";
import TopHomeContent from "../components/TopHomeContent";

const Home = ({ handleUserMessage, messages, stopGenerating }) => {
  const chatOpen = useAppStore((state) => state.chatOpen);

  return (
    <StyledHome className="styled-home">
      <HomeContentWrapper style={{ opacity: chatOpen ? 0 : 1 }}>
        <TopHomeContent handleUserMessage={handleUserMessage} />
        <TestPrompts onSubmit={handleUserMessage} />
      </HomeContentWrapper>
      {chatOpen && <ChatHistory messages={messages} />}
      <ChatInput
        stopGenerating={stopGenerating}
        messages={messages}
        onSubmit={handleUserMessage}
      />
    </StyledHome>
  );
};

const StyledHome = styled(animated.div)`
  display: flex;
  flex-direction: column;
  position: relative;
  overflow-x: hidden;
  height: 100vh;
`;

const HomeContentWrapper = styled.div`
  position: relative;
  transition: opacity 0.25s ease-in-out;
  display: flex;
  flex-direction: column;
  gap: 40px;
  overflow: hidden;
  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    gap: 0px;
  }
`;

export default Home;
