import React, { useEffect, useRef } from "react";
import styled from "@emotion/styled";
import UserMessage from "./UserMessage";
import SystemMessage from "./SystemMessage";
import KeepChattingModal from "./KeepChattingModal";
import ThinkingIndicator from "./ThinkingIndicator";
import { useAppStore } from "../helpers/useAppStore";

const ChatHistory = ({ messages }) => {
  const containerRef = useRef();
  const isThinking = useAppStore((state) => state.isThinking);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTo({
        top: containerRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  }, [messages, isThinking]);

  console.log(messages);

  const renderMessages = () => {
    if (messages.length === 0) {
      return null;
    }

    return messages.map((message, index) => {
      if (message.isUser) {
        return (
          <UserMessage
            messages={messages}
            messageNumber={index}
            key={index}
            text={message.text}
          />
        );
      }

      return (
        <SystemMessage
          messages={messages}
          messageNumber={index}
          key={index}
          text={message.text}
        />
      );
    });
  };

  return (
    <StyledChatHistory>
      <OverflowGradientTop />
      <OverflowContainer ref={containerRef}>
        <ChatContainer>
          <StyledMessagesContainer>
            {renderMessages()}
            <ThinkingIndicator />
          </StyledMessagesContainer>
        </ChatContainer>
        <PaddingWrapper />
        <KeepChattingModal messages={messages} />
      </OverflowContainer>
      <OverflowGradient />
    </StyledChatHistory>
  );
};

const StyledChatHistory = styled.div`
  background: ${(props) => props.theme.colors.lightGrey};
  border: none;
  min-height: 100vh;
  width: 100%;
  position: absolute;
`;

const OverflowContainer = styled.div`
  height: calc(100vh - 100px);
  overflow: auto;
  position: relative;
  padding-bottom: 100px;
  /* Style the scrollbar */
  &::-webkit-scrollbar {
    width: 6px;
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    max-height: 161px;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    &::-webkit-scrollbar {
      display: none;
    }
    margin-right: 0px;
  }

  margin-right: 3px;
`;

const ChatContainer = styled.div`
  width: 700px;
  margin: 0 auto;
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    width: 100%;
  }
`;

const StyledMessagesContainer = styled.div`
  padding-top: 40px;
  max-height: 70%;
  width: 600px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 18px;
  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    padding-top: 70px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    width: calc(100% - 100px);
    padding-top: 100px;
  }
`;

const OverflowGradient = styled.div`
  position: fixed;
  bottom: 0px;
  left: 0;
  width: 90%;
  height: 100px;
  z-index: 0;
  margin: 0 auto;
  background: linear-gradient(
    180deg,
    rgba(245, 245, 245, 0) 0%,
    rgba(245, 245, 245, 1) 30%
  );
  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    width: 100%;
  }
`;

const OverflowGradientTop = styled(OverflowGradient)`
  position: fixed;
  top: 0px;
  left: 0;
  width: 0%;
  height: 0px;
  margin: 0 auto;
  background: linear-gradient(
    180deg,
    rgba(245, 245, 245, 1) 40%,
    rgba(245, 245, 245, 0) 100%
  );
  z-index: 1;
  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    width: 100%;
    height: 80px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    height: 100px;
  }
`;

const PaddingWrapper = styled.div`
  height: 40px;
  width: 100%;
  background: none;
  position: relative;
  z-index: 2;
`;

export default ChatHistory;
