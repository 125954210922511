import React, { useState } from "react";
import styled from "@emotion/styled";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { animated, useSpring } from "@react-spring/web";

const CopyTextButton = ({ copyText, show }) => {
  const [copied, setCopied] = useState(false);
  const text = copied ? "copied!" : "copy text";
  const handleClick = () => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1000);
  };
  const animationProps = useSpring({
    opacity: show ? 1 : 0,
    y: show ? 0 : 30,
    delay: 350,
  });
  return (
    <CopyToClipboard text={copyText}>
      <StyledCopyTextButton
        style={animationProps}
        copied={copied ? "true" : "false"}
        onClick={handleClick}
      >
        <StyledText copied={copied}>{text}</StyledText>
      </StyledCopyTextButton>
    </CopyToClipboard>
  );
};
const StyledCopyTextButton = styled(animated.button)`
  padding: 10px 12px;
  background: ${(props) => (props.copied === "true" ? "#0071CE" : "white")};
  border-radius: 5px;
  border: none;
  cursor: pointer;
  width: ${(props) => (props.copied === "true" ? "70px" : "88px")};
  white-space: nowrap;
  transition: background-color 0.2s ease-in-out, width 0.1s ease-in-out;
  p {
    color: ${(props) =>
      props.copied === "true" ? "white" : props.theme.colors.blue};
  }
  &:hover {
    p {
      color: ${(props) =>
        props.copied === "true" ? "white" : props.theme.colors.blue};
    }
  }
`;

const StyledText = styled.p`
  font-family: "Inter", sans-serif;
  font-size: 11px;
  font-weight: 700;
  text-transform: uppercase;
  color: ${(props) =>
    props.copied === "true" ? "white" : props.theme.colors.darkGrey};
  transition: color 0.25s ease-in-out;
`;
export default CopyTextButton;
