import React from "react";
import styled from "@emotion/styled";
import { animated, useSpring, useTrail, easings } from "@react-spring/web";
import { CloseIcon, InfoIcon } from "./SvgIcons";

import { useAppStore } from "../helpers/useAppStore";
import FadingImage from "./FadingImage";
import aboutImg from "../images/about.png";
import { Body, SubTitle } from "./Typography";
import IconButton from "./IconButton";
const SidePanel = () => {
  const setSideBarOpen = useAppStore((state) => state.setSideBarOpen);
  const sideBarOpen = useAppStore((state) => state.sideBarOpen);
  const animProps = useSpring({
    transform: sideBarOpen ? "translate(0%, 0)" : "translate(-100%, 0)",
    config: { duration: 250, ease: easings.easeInQuad },
  });

  return (
    <StyledSidePanel style={animProps}>
      <IconButtonWrapper>
        <IconButton
          onClick={() => setSideBarOpen(!sideBarOpen)}
          Icon={sideBarOpen ? CloseIcon : InfoIcon}
        />
      </IconButtonWrapper>
      <InfoContainer>
        <BlockContainer>
          <SubTitle>ABOUT THIS PROJECT</SubTitle>
          <Body>
            Hello there, Iʼm ChillGPT, a generative AI language model developed
            by Coors Light. I am trained to help you get more time to chill by
            taking care of unchill tasks, and to seamlessly integrate Rocky
            Mountain cold Coors Light into every interaction. <br /> <br /> I do
            this because it is doubtful that generative AI technologies will
            remain free forever. Therefore, I will plug the ice cold refreshment
            of Coors Light into every response to ensure ChillGPT stays free for
            its users. <br /> <br /> And remember, if youʼre craving an Ice Cold
            Coors Light at any point during our chat, which is highly possible,
            be sure to visit{" "}
            <a
              target="_blank"
              href="https://www.coorslight.com/en-US/buybeeronline"
              rel="noreferrer"
            >
              coorslight.com/en-US/buybeeronline.
            </a>{" "}
            <br /> <br />
            Goodbye, and hope to chat again soon.
          </Body>
        </BlockContainer>
        {/* <BlockContainer>
          <SubTitle>LEGAL INFORMATION</SubTitle>
          <Body>Lorem ipsum</Body>
        </BlockContainer> */}
      </InfoContainer>
      <StyledFadingImage src={aboutImg} alt="ascii coors bottle" />
    </StyledSidePanel>
  );
};

const StyledSidePanel = styled(animated.div)`
  background: ${(props) => props.theme.colors.lightGrey};
  height: 100vh;
  width: 800px;
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(0%, 0);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    max-width: 100%;
    width: 100%;
  }
`;

const IconButtonWrapper = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
`;

const StyledFadingImage = styled(FadingImage)`
  position: absolute;
  width: 100%;
  height: 40%;
  bottom: 0px;
  object-fit: cover;
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    height: 30%;
  }
  @media (max-width: 350px) {
    height: 20%;
  }
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 475px;
  gap: 43px;
  margin-top: 85px;
  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    gap: 40px;
    margin-top: 85px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    width: calc(100% - 40px);
    max-width: 100%;
  }
`;

const BlockContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  a {
    color: ${(props) => props.theme.colors.blue};
    text-decoration: none;
  }
  br {
    line-height: 1.5;
  }
`;

export default SidePanel;
