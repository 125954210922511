import { create } from "zustand";

export const useAppStore = create((set) => ({
  sideBarOpen: false,
  setSideBarOpen: (val) => set((state) => ({ sideBarOpen: val })),
  chatOpen: false,
  setChatOpen: (val) => set((state) => ({ chatOpen: val })),
  chatInputHomeY: null,
  setChatInputHomeY: (val) => set((state) => ({ chatInputHomeY: val })),
  isTyping: false,
  setIsTyping: (val) => set(() => ({ isTyping: val })),
  isThinking: false,
  setIsThinking: (val) => set(() => ({ isThinking: val })),
  shareOpen: false,
  setShareOpen: (val) => set(() => ({ shareOpen: val })),
  introComplete: false,
  setIntroComplete: (val) => set(() => ({ introComplete: val })),
}));
