import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { useSpring, animated } from "@react-spring/web";

const ImageWrapper = styled.div`
  position: relative;
  display: inline-block;
`;

const StyledImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const FadingImage = ({ src, alt, ...props }) => {
  const [loaded, setLoaded] = useState(false);

  const springProps = useSpring({
    from: { opacity: 0 },
    to: { opacity: loaded ? 1 : 0 },
  });

  useEffect(() => {
    const img = new Image();
    img.src = src;
    img.onload = () => {
      setLoaded(true);
    };
  }, [src]);

  return (
    <ImageWrapper {...props}>
      <animated.div style={springProps}>
        <StyledImage src={src} alt={alt} />
      </animated.div>
    </ImageWrapper>
  );
};

export default FadingImage;
