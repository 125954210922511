// src/theme.js
const theme = {
  colors: {
    blue: "#0071CE",
    lightBlue: "#F2F7FC",
    mediumBlue: "#94B4E0",
    darkBlue: "#092045",
    white: "rgba(245, 245, 245, 1)",
    lightGrey: "#F5F5F5",
    darkGrey: "#76777A",
    black: "#000000",
    red: "#F21341",
    chatBg: "#E8EAED",
  },
  shadows: {
    regularGray: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    regular: "0px 4px 10px rgba(63, 125, 202, 0.1)",
    hovered: "0px 4px 10px rgba(63, 125, 202, 0.3)",
  },
  breakpoints: {
    sm: "480px",
    md: "768px",
  },
};

export default theme;
