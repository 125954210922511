import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { useSpring, animated, config } from "@react-spring/web";

const IconButton = ({ Icon, onClick, small, as = "button" }) => {
  const [props, api] = useSpring(() => ({
    from: { opacity: 0 },
    to: { opacity: 1 },
    config: config.molasses,
  }));

  useEffect(() => {
    api.start({ from: { opacity: 0 }, to: { opacity: 1 } });
  }, [Icon, api]);

  return (
    <StyledIconButton as={as} small={small} onClick={onClick} tabIndex={0}>
      <animated.div>
        <Icon />
      </animated.div>
    </StyledIconButton>
  );
};

const StyledIconButton = styled.button`
  background: #fff;
  height: ${(props) => (props.small ? "35px" : "54px")};
  width: ${(props) => (props.small ? "35px" : "54px")};
  border-radius: 50%;
  box-shadow: ${(props) => props.theme.shadows.regular};
  transition: box-shadow 0.2s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;
  svg {
    width: ${(props) => (props.small ? "13px" : "20px")};
    height: ${(props) => (props.small ? "13px" : "20px")};
  }
  @media (min-width: 480px) {
    &:hover {
      box-shadow: ${(props) => props.theme.shadows.hovered};
      svg {
        scale: 1.2;
      }
      &:active {
        svg {
          scale: 0.9;
        }
      }
    }
  }
`;

export default IconButton;
