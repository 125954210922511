import React from "react";
import styled from "@emotion/styled";
import IconButton from "./IconButton";
import useScreenWidth from "../helpers/useScreenWidth";
import { useAppStore } from "../helpers/useAppStore";
import { EmailIcon, FacebookIcon, ShareIcon, TwitterIcon } from "./SvgIcons";
import { useSpring, animated } from "@react-spring/web";
import {
  FacebookShareButton,
  TwitterShareButton,
  EmailShareButton,
} from "react-share";

const HeaderRight = () => {
  const { isMobile } = useScreenWidth();
  const setShareOpen = useAppStore((state) => state.setShareOpen);
  const introComplete = useAppStore((state) => state.introComplete);
  const animProps = useSpring({
    // right: introComplete ? "10px" : "-10px",
    // config: { duration: 500 },
    delay: 1000,
  });
  const shareUrl = window.location.href;
  console.log(window.location.href);
  const title =
    "Have a chat with mountain cold Coors Lightʼs ChillGPT, the first fully free, ad-based AI chatbot.";
  return (
    <StyledHeaderRight style={animProps}>
      {!isMobile && (
        <SocialIcons>
          <FacebookShareButton url={shareUrl} quote={title}>
            <IconButton as="div" small Icon={FacebookIcon} />
          </FacebookShareButton>
          <TwitterShareButton url={shareUrl} title={title}>
            <IconButton as="div" small Icon={TwitterIcon} />
          </TwitterShareButton>
          <EmailShareButton url={shareUrl} subject={"ChillGPT"} body={title}>
            <IconButton as="div" small Icon={EmailIcon} />
          </EmailShareButton>
        </SocialIcons>
      )}
      {isMobile && (
        <ShareButtonWrapper>
          <IconButton onClick={() => setShareOpen(true)} Icon={ShareIcon} />
        </ShareButtonWrapper>
      )}
    </StyledHeaderRight>
  );
};

const StyledHeaderRight = styled(animated.div)`
  /* position: absolute;
  right: 10px;
  top: 10px; */
  margin-right: 10px;
  z-index: 10;
`;

const SocialIcons = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 9px;
  align-items: center;
  z-index: 10;
  justify-content: center;
`;

const ShareButtonWrapper = styled.div`
  /* position: absolute; */
  z-index: 10;
`;

export default HeaderRight;
