import styled from "@emotion/styled";
import React, { useState, useCallback } from "react"; // Import useCallback for memoization
import SendButton from "./SendButton";
import TextareaAutosize from "react-textarea-autosize";
import { useAppStore } from "../helpers/useAppStore";
import { useSpring, animated } from "@react-spring/web";
import ChatButton from "./ChatButton";
import Lottie from "lottie-react";
import lottieAnim from "../json/typing.json";
// ChatInput component to handle user input and submission
const ChatInput = ({ onSubmit, messages, stopGenerating }) => {
  const chatInputHomeY = useAppStore((state) => state.chatInputHomeY);
  const chatOpen = useAppStore((state) => state.chatOpen);
  const isTyping = useAppStore((state) => state.isTyping);
  const isThinking = useAppStore((state) => state.isThinking);
  const [input, setInput] = useState("");
  const springProps = useSpring({
    bottom: chatOpen ? 35 : chatInputHomeY,
    y: chatOpen ? "0%" : "20%",
    display: chatOpen ? "block" : "none",
  });

  // Handle input submission and reset the input
  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      if (input.trim()) {
        onSubmit(input);
        setInput("");
      }
    },
    [input, onSubmit]
  );

  // Check for Enter key press and call handleSubmit
  const handleKeyPress = useCallback(
    (e) => {
      if (
        e.key === "Enter" &&
        !e.shiftKey &&
        input.length > 0 &&
        !isTyping &&
        !isThinking
      ) {
        e.preventDefault();
        handleSubmit(e);
      }
    },
    [handleSubmit, input.length, isThinking, isTyping]
  );

  // Update input value on change
  const handleInputChange = useCallback((e) => {
    setInput(e.target.value);
  }, []);
  return (
    <StyledForm style={springProps} onSubmit={handleSubmit}>
      <InputWrapper>
        <StyledInput
          type="text"
          value={input}
          onKeyPress={handleKeyPress}
          onChange={handleInputChange}
          placeholder={
            messages && messages.length
              ? isTyping
                ? "Typing..."
                : "Send another message..."
              : "Send a message..."
          }
        />

        <StopGeneraratingContainer show={isTyping}>
          <ChatButton onClick={stopGenerating} text="stop generating" />
        </StopGeneraratingContainer>

        {(isThinking || isTyping) && (
          <LottieWrapper>
            <Lottie animationData={lottieAnim} />
          </LottieWrapper>
        )}
        {!isThinking && !isTyping && (
          <SendButton handleClick={handleSubmit} isDisabled={!input.length} />
        )}
      </InputWrapper>
      <LegalInfo>
        CHILLGPT IS FOR ENTERTAINMENT PURPOSES AND MAY PRODUCE INACCURATE
        INFORMATION. INTENDED FOR LEGAL DRINKING AGE CONSUMERS ONLY. CELEBRATE
        RESPONSIBLY®
      </LegalInfo>
    </StyledForm>
  );
};

// Form styled component
const StyledForm = styled(animated.form)`
  position: fixed;
  width: 100%;
  z-index: 3;
`;

// Input wrapper styled component
const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: fit-content;
  margin: 0 auto;
  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    width: 80%;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    max-width: calc(100% - 20px);
    width: calc(100% - 20px);
  }
`;

// Input styled component
const StyledInput = styled(TextareaAutosize)`
  font-family: "Inter", sans-serif;
  line-height: 22px;
  max-width: 650px;
  width: 650px;
  padding: 16px;
  padding-right: 60px;
  border-radius: 27px;
  background: #ffffff;
  border: none;
  box-shadow: ${(props) => props.theme.shadows.regular};
  font-size: 14px;
  color: ${(props) => props.theme.colors.black};
  transition: box-shadow 0.2s ease-in-out;
  resize: none;
  position: relative;
  z-index: 2;

  &:focus,
  &:active {
    outline: none;
    box-shadow: ${(props) => props.theme.shadows.hovered};
  }
  &::placeholder {
    font-size: 14px;
    color: ${(props) => props.theme.colors.blue};
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    max-width: 100%;
    width: 100%;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    max-width: calc(100% - 68px);
    width: calc(100% - 68px);
  }
`;

const StopGeneraratingContainer = styled.div`
  position: absolute;
  left: 50%;
  z-index: ${(props) => (props.show ? 999999 : 0)};
  transform: ${(props) =>
    props.show
      ? "translate(-50%, calc(-100% - 23px))"
      : "translate(-50%, calc(-100% + 50px))"};
  top: 0%;
  opacity: ${(props) => (props.show ? 1 : 0)};
  transition: all 0.5s ease-in-out;
`;

const LottieWrapper = styled.div`
  position: absolute;
  right: 10px;
  z-index: 10;

  width: 50px;
  height: 50px;
`;
const LegalInfo = styled.p`
  font-size: 9px;
  color: #a0a0a0;
  width: 700px;
  position: absolute;
  text-align: center;
  left: 50%;
  transform: translateX(-50%) translateY(5px);
  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    width: 90%;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    width: 98%;
    font-size: 8px;
  }
`;
export default ChatInput;
