import React from "react";
import styled from "@emotion/styled";
import { Body } from "./Typography";
import CopyTextButton from "./CopyTextButton";
import { useAppStore } from "../helpers/useAppStore";
import { animated, useSpring } from "@react-spring/web";

const AnimatedWord = ({ word, index }) => {
  const animationProps = useSpring({
    from: { color: "#0071CE" },
    to: { color: "#000" },
    config: { duration: 120 },
    delay: 120,
  });

  return <animated.span style={animationProps}>{word} </animated.span>;
};

const SystemMessage = ({ text, messages, messageNumber }) => {
  const isTyping = useAppStore((state) => state.isTyping);
  const isLastMessage = messages.length - 1 === messageNumber;

  // Function to split the text on \n and add line breaks
  const formatTextWithLineBreaks = (text) => {
    if (!text || text.length === 0) {
      return null;
    }

    return text.split("\n").flatMap((line, index) => {
      const words = line
        .split(" ")
        .map((word, wordIndex) => (
          <AnimatedWord
            key={`${index}-${wordIndex}`}
            word={word}
            index={wordIndex}
          />
        ));

      return [
        ...words,
        index < text.split("\n").length - 1 && (
          <React.Fragment key={`br-${index}`}>
            <br />
          </React.Fragment>
        ),
      ];
    });
  };

  const formattedText = formatTextWithLineBreaks(text);

  return (
    <StyledSystemMessage>
      <MessageContainer>
        <UserIcon />
        <StyledMessage>{formattedText}</StyledMessage>
      </MessageContainer>
      <CopyTextButton show={!isTyping || !isLastMessage} copyText={text} />
    </StyledSystemMessage>
  );
};

const UserIcon = () => {
  return (
    <StyledSystemIcon
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="16" cy="16" r="16" fill="white" />
      <path
        d="M14.8426 8.48926L6.4082 20.9509H26.2404L19.7057 11.3767L18.3379 13.4283L14.8426 8.48926Z"
        fill="#0071CE"
      />
    </StyledSystemIcon>
  );
};

const StyledSystemMessage = styled.div`
  align-self: flex-start;
  display: flex;
  justify-content: center;
  gap: 10px;
  flex-direction: column;
  position: relative;
`;

const MessageContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const StyledMessage = styled(Body)`
  color: ${(props) => props.theme.colors.black};
  text-align: left;
`;

const StyledSystemIcon = styled.svg`
  width: 32px;
  height: 32px;
  position: absolute;
  min-height: 32px;
  top: 0;
  min-width: 32px;
  left: 0;
  transform: translateY(-3px) translateX(calc(-100% - 10px));
`;

export default SystemMessage;
